import { Fragment } from 'react';
import PropTypes from 'prop-types';

import BannersGroup from 'src/components/Banner/BannersGroup';

import ClickmeReferralLink from 'src/components/Clickme/ReferralLink';

const Clickme = ({ banners, showReferralLink, children, groupClassNames }) => (
    <Fragment>
        {showReferralLink && <ClickmeReferralLink />}
        <BannersGroup banners={banners} groupClassNames={groupClassNames}>
            {children}
        </BannersGroup>
    </Fragment>
);

Clickme.defaultProps = {
    showReferralLink: false,
    groupClassNames: '',
};

Clickme.propTypes = {
    banners: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
    showReferralLink: PropTypes.bool,
    children: PropTypes.node,
    groupClassNames: PropTypes.string,
};

export default Clickme;
