import { ReactNode } from 'react';

import { Card, Checkbox, HSpacing, Link, Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import formatDate from 'Modules/formatDate';
import { RenameUrl } from 'src/components/SavedSearch/types';
import translation from 'src/components/translation';

import Name from 'src/components/SavedSearch/SavedSearchItem/Name';

import styles from './styles.less';

const TrlKeys = {
    subscribe: 'savedSearch.subscribe',
};

interface SavedSearchItemProps {
    isChecked: boolean;
    isSubscribeActive: boolean;
    id: number;
    name: string;
    creationTimestamp: string;
    renameUrl: RenameUrl;
    handleChange: () => void;
    subscribe: () => void;
    content: ReactNode;
    footer: ReactNode;
}

const SavedSearchItem: TranslatedComponent<SavedSearchItemProps> = ({
    isChecked,
    isSubscribeActive,
    id,
    name,
    creationTimestamp,
    renameUrl,
    handleChange,
    subscribe,
    content,
    footer,
    trls,
}) => {
    return (
        <Card padding={24} borderRadius={16} data-qa="autosearch-item" showBorder stretched>
            <div className={styles.savedSearchItemHeaderContainer}>
                <div className={styles.savedSearchItemHeader}>
                    <Checkbox
                        checked={isChecked}
                        onChange={handleChange}
                        data-qa="autosearch-checkbox"
                        autoComplete="off"
                    />
                    <HSpacing default={12} />
                    <Name id={id} name={name} renameUrl={renameUrl} />
                </div>
                <HSpacing default={16} />
                <Text typography="label-2-regular" style="secondary">
                    <span className={styles.savedSearchItemDate} suppressHydrationWarning>
                        {formatDate(new Date(Number(creationTimestamp)), 'd MMMM')}
                    </span>
                </Text>
            </div>
            <div className={styles.savedSearchItemContentContainer}>
                <HSpacing default={36} />
                <div className={styles.savedSearchItemContent}>
                    <VSpacing default={12} />
                    {content}
                    <VSpacing default={12} />
                    <div className={styles.savedSearchItemFooter}>
                        {footer}
                        {!isSubscribeActive && (
                            <Link Element="button" onClick={subscribe} data-qa="autosearch__subscribe-one">
                                {trls[TrlKeys.subscribe]}
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default translation(SavedSearchItem);
