import { useState } from 'react';

import { Button, Link as MagritteLink, Text, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

import {
    emailVerificationSendError,
    emailVerificationSend,
    SendDoubleOptInResponse,
} from 'src/pages/VacancySavedSearch/components/EmailVerificationNotification';

const TrlKeys = {
    needEmail: 'savedSearches.needEmail',
    inSettings: 'savedSearches.inSettings',
    subscriptionNeeded: 'savedSearches.subscriptionNeeded',
    sendMeEmail: 'subscribe.sendMeEmail',
};

const verificationSendUrl = '/email_verification/send';

declare global {
    interface FetcherPostApi {
        [verificationSendUrl]: {
            body: void;
            queryParams: void;
            response: { notification: string; email?: string; mailerUrl?: string };
        };
    }
}

const EmailMessage: TranslatedComponent = ({ trls }) => {
    const { email, emailVerified } = useSelector((state) => state.vacancySavedSearches);
    const { addNotification } = useNotification();

    const [isLoading, setLoading] = useState(false);

    const sendMeEmail = () => {
        setLoading(true);
        fetcher
            .post(verificationSendUrl)
            .then(({ data: { notification, ...rest } }) => {
                if (notification === SendDoubleOptInResponse.EmailVerificationWasSendAgain) {
                    addNotification(emailVerificationSend);
                } else {
                    addNotification(emailVerificationSendError, {
                        props: { notification: notification as SendDoubleOptInResponse, ...rest },
                    });
                }
            })
            .catch(() => {
                addNotification(emailVerificationSendError);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (email && emailVerified) {
        return null;
    }

    if (email && !emailVerified) {
        return (
            <>
                <Text>{trls[TrlKeys.subscriptionNeeded]}</Text>
                <VSpacing default={12} />
                <Button onClick={sendMeEmail} mode="primary" style="accent" loading={isLoading}>
                    {trls[TrlKeys.sendMeEmail]}
                </Button>
                <VSpacing default={40} />
            </>
        );
    }

    return (
        <>
            <Text>
                {formatToReactComponent(trls[TrlKeys.needEmail], {
                    '{0}': (
                        <MagritteLink
                            Element={Link}
                            to="/applicant/settings#email"
                            typography="paragraph-2-regular"
                            inline
                        >
                            {trls[TrlKeys.inSettings]}
                        </MagritteLink>
                    ),
                })}
            </Text>
            <VSpacing default={40} />
        </>
    );
};

export default translation(EmailMessage);
