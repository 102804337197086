import { useLayoutEffect } from 'react';

import PageErrorWrapper from 'src/components/PageErrorWrapper';
import { PageHead, PageTitle, PageMeta, PageDescription, PageKeywords, PageLink } from 'src/components/PageHead';

import DefaultLayout from 'src/app/layouts/BlokoLayouts/DefaultLayout';
import EmptyLayout from 'src/app/layouts/BlokoLayouts/EmptyLayout';
import HeaderLayout from 'src/app/layouts/BlokoLayouts/HeaderLayout';
import LightPageLayout from 'src/app/layouts/BlokoLayouts/LightPageLayout';
import SideBarLayout from 'src/app/layouts/BlokoLayouts/SideBarLayout';
import FullPageLayout from 'src/app/layouts/FullPageLayout';
import { PageLayoutWithCustomLayout } from 'src/app/layouts/PageLayout.types';
import { clearXSLMetaTags } from 'src/app/layouts/utils';

const RSS_MIME = 'application/rss+xml';

const PageLayout: PageLayoutWithCustomLayout = ({
    children,
    layout: LayoutComponent = DefaultLayout,
    errorWrapper: ErrorWrapperComponent = PageErrorWrapper,
    ogTitle,
    description,
    ogDescription,
    keywords,
    canonical,
    rssTitle,
    rssHref,
    removeTitle = false,
    afterMenuContent,
    useCleanContainer,
    ogUrl,
    ogType,
    ...layoutProps
}) => {
    const { title } = layoutProps;

    useLayoutEffect(
        () => clearXSLMetaTags({ title, ogTitle, removeTitle, description, ogDescription, canonical, keywords }),
        [title, removeTitle, description, canonical, keywords, ogTitle, ogDescription]
    );

    const content = (
        <>
            <PageHead>
                {title && (
                    <>
                        <PageTitle>{title}</PageTitle>
                        <PageMeta property="og:title" content={ogTitle || title} />
                    </>
                )}
                {description && <PageDescription content={description} openGraphContent={ogDescription} />}
                {keywords && <PageKeywords content={keywords} />}
                {canonical && <PageLink rel="canonical" href={canonical} />}
                {rssHref && (title || rssTitle) && (
                    <PageLink title={rssTitle || title} type={RSS_MIME} rel="alternate" href={rssHref} />
                )}
                {ogUrl && <PageMeta property="og:url" content={ogUrl} />}
                {ogType && <PageMeta property="og:type" content={ogType} />}
            </PageHead>
            <LayoutComponent {...layoutProps}>{children}</LayoutComponent>
        </>
    );

    return (
        <FullPageLayout afterMenuContent={afterMenuContent} useCleanContainer={useCleanContainer}>
            <ErrorWrapperComponent>{content}</ErrorWrapperComponent>
        </FullPageLayout>
    );
};

export { EmptyLayout, DefaultLayout, SideBarLayout, LightPageLayout, HeaderLayout };
export default PageLayout;
