import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import Text, { TextSize } from 'bloko/blocks/text';

import translation from 'src/components/translation';

const ReferralLink = ({ trls }) => (
    <div className="clickme__link-wrapper">
        <Link
            to="https://clickme.hh.ru/new/dashboard?utm_source=block_referral"
            rel="noopener noreferrer"
            className="clickme__link"
            target="_blank"
        >
            <Text size={TextSize.Small}>{trls[ReferralLink.trls.link]}</Text>
        </Link>
    </div>
);

ReferralLink.trls = {
    link: 'clickme.adv.here',
};

ReferralLink.propTypes = {
    trls: PropTypes.object,
};

export default translation(ReferralLink);
