import PropTypes from 'prop-types';

import { GridColumn } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';

import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import ClickmeList from 'src/components/Clickme/List';
import ClickmeReferralLink from 'src/components/Clickme/ReferralLink';
import { useSelector } from 'src/hooks/useSelector';

const ApplicantSideBar = ({ isMagritte }) => {
    const rightBanners = useSelector(({ banners }) => banners[`right-column`]);

    if (isMagritte) {
        return (
            <>
                <GridColumn xs={4} l={3}>
                    {rightBanners && rightBanners.map((banner) => <AdsExternalBanner key={banner.id} {...banner} />)}
                </GridColumn>
                <ClickmeList
                    isMagritte
                    clickmeReferralLink={
                        <GridColumn xs={4} l={3}>
                            <ClickmeReferralLink />
                        </GridColumn>
                    }
                />
            </>
        );
    }

    return (
        <>
            <Column xs="4" m="4" l="5">
                {rightBanners && rightBanners.map((banner) => <AdsExternalBanner key={banner.id} {...banner} />)}
            </Column>
            <ClickmeList
                clickmeReferralLink={
                    <Column xs="4" m="4" l="5">
                        <ClickmeReferralLink />
                    </Column>
                }
            />
        </>
    );
};

ApplicantSideBar.propTypes = {
    isMagritte: PropTypes.bool,
};

export default ApplicantSideBar;
